import React, {useEffect, useState} from "react";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import "./LoadingScreen.css"

export default function LoadingScreen(props) {
	const [show, setShow] = useState(true)
	useEffect(() => {
		let timeout = setTimeout(() => setShow(false), 1000)
		return () => {
			clearTimeout(timeout)
		}
	}, [])
	return (
		<><Modal open={show} 
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
			  timeout: 300,
			}}>
			<Fade in={show} timeout={!show?500:0}>
		<div className="loading-box" >
			<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080.000000 1080.000000" preserveAspectRatio="xMidYMid meet" className="logo-box ">
				<g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
					<path className="logo-loading roof" d="M5350 9386 c-34 -13 -238 -102 -330 -144 -49 -22 -191 -85 -310 -137 -144 -64 -516 -228 -680 -300 -91 -40 -223 -98 -295 -130 -71 -31 -204 -90 -295 -130 -91 -40 -223 -99 -295 -130 -71 -32 -148 -65 -170 -75 -22 -10 -98 -44 -170 -75 -71 -32 -148 -65 -170 -75 -22 -10 -96 -43 -165 -73 -69 -30 -145 -63 -170 -74 -25 -11 -81 -36 -125 -56 -148 -65 -340 -149 -390 -172 -27 -12 -74 -32 -102 -45 -60 -26 -93 -69 -93 -121 1 -47 53 -160 85 -184 57 -43 76 -36 770 275 94 42 440 194 720 317 44 19 108 48 143 64 34 16 64 29 67 29 3 0 33 13 67 29 35 16 214 95 398 176 184 81 373 164 420 185 47 21 141 63 210 93 69 30 242 107 385 170 143 63 280 123 305 133 25 9 50 20 55 24 6 4 49 24 98 45 l87 37 88 -37 c48 -21 92 -41 97 -45 6 -4 30 -15 55 -24 25 -10 162 -70 305 -133 143 -63 316 -140 385 -170 69 -30 163 -72 210 -93 47 -21 236 -104 420 -185 184 -81 363 -160 398 -176 34 -16 64 -29 67 -29 3 0 33 -13 67 -29 35 -16 99 -45 143 -64 280 -123 626 -275 720 -317 694 -311 713 -318 770-275 32 24 84 137 85 184 0 52 -33 95 -93 121 -28 13 -74 33 -102 45 -50 23 -242 107 -390 172 -44 20 -100 45 -125 56 -25 11 -101 44 -170 74 -69 30 -143 63 -165 73 -22 10 -98 43 -170 75 -71 31 -148 65 -170 75 -22 10 -98 43 -170 75 -71 31 -204 90 -295 130 -91 40 -223 99 -295 130 -71 32 -204 90 -295 130 -164 72 -536 236 -680 300 -119 52 -261 115 -310 137 -219 100 -358 157 -380 157 -8 0 -31 -6 -50 -13z" />
					<path className="logo-loading n-up" d="M2320 7231 c-347 -74 -625 -348 -717 -707 -16 -62 -18 -125 -18 -581 l0 -513 33 -32 c32 -33 33 -33 131 -33 85 0 104 3 128 21 53 40 53 34 53 539 0 283 5 488 11 515 5 25 28 83 51 130 55 114 150 211 263 267 104 52 159 65 276 65 166 -1 302 -61 425 -188 69 -71 135 -193 153 -282 7 -33 11 -222 12 -505 0 -487 2 -504 52 -541 24 -18 43 -21 121 -21 107 0 137 12 161 62 14 30 16 90 12 536 -3 484 -4 505 -26 582 -28 101 -97 246 -148 317 -140 189 -353 326 -577 372 -105 21 -286 20 -396 -3z" />
					<path className="logo-loading e-up" d="M4250 7241 c-340 -73 -603 -323 -706 -671 -26 -88 -28 -108 -28 -260 0 -150 3 -173 28 -255 55 -182 129 -307 258 -431 139 -133 269 -202 458 -240 120 -24 127 -25 568 -22 408 3 449 5 473 21 14 9 31 29 37 43 15 32 16 195 1 227 -24 52 -19 52 -534 58 -346 4 -491 9 -520 18 -195 62 -352 225 -410 425 -26 88 -23 253 5 337 53 157 155 283 286 351 127 67 137 68 636 68 493 0 503 1 532 59 11 21 16 58 16 125 0 92 -1 97 -29 128 l-29 33 -479 2 c-429 2 -487 0 -563 -16z" />
					<path className="logo-loading e-up" d="M4341 6480 c-66 -16 -76 -36 -76 -164 0 -123 9 -146 62 -167 13 -5 232 -9 488 -9 433 -1 466 0 489 17 38 28 46 56 46 163 0 88 -2 102 -22 125 -13 14 -41 30 -63 35 -48 11 -876 11 -924 0z"/>
					<path className="logo-loading o-up" d="M6150 7240 c-114 -24 -171 -44 -260 -92 -232 -123 -401 -338 -471 -598 -30 -110 -33 -346 -6 -452 72 -290 258 -519 522 -644 98 -46 240 -81 364 -90 126 -10 321 31 444 92 266 132 436 341 513 629 66 244 15 535 -131 754 -210 316 -608 480 -975 401z m312 -341 c29 -6 90 -28 135 -50 146 -70 255 -194 309 -354 35 -105 38 -254 5 -355 -61 -192 -210 -340 -404 -402 -73 -24 -246 -29 -317 -9 -208 58 -382 233 -435 438 -19 74 -19 213 0 288 58 222 243 401 460 445 60 12 187 11 247 -1z" />
					<path className="logo-loading n-up" d="M8042 7225 c-272 -70 -493 -247 -614 -490 -44 -88 -53 -110 -80 -215 -21 -81 -22 -110 -25 -560 -5 -518 -3 -535 50 -574 24 -18 43 -21 128 -21 98 0 99 0 131 33 l33 32 5 498 6 497 26 67 c74 193 216 332 398 389 85 27 241 29 325 4 195 -56 341 -197 413 -398 l26 -72 4 -492 c4 -471 5 -494 23 -517 49 -63 230 -62 293 1 l27 27 -3 525 -3 526 -28 88 c-105 337 -369 583 -705 657 -129 29 -308 27 -430 -5z"/>
					<path className="logo-loading floor" d="M303 5063 c-12 -2 -33 -16 -47 -30 -25 -25 -26 -31 -26 -137 0 -125 7 -143 64 -170 31 -15 437 -16 5093 -16 2987 0 5074 4 5097 10 22 5 51 20 64 34 23 22 26 35 30 113 5 114 -10 162 -58 185 -33 17 -297 18 -5115 17 -2794 -1 -5090 -4 -5102 -6z"/>
					<path className="logo-loading h" d="M863 4320 c-21 -13 -35 -31 -42 -57 -16 -57 -14 -2466 2 -2513 18 -51 57 -69 157 -71 93 -1 125 7 155 41 19 21 20 40 26 524 6 548 4 528 71 658 74 143 224 261 384 303 74 19 213 19 287 0 101 -26 196 -82 273 -159 77 -77 120 -145 155 -246 23 -64 24 -72 27 -550 2 -312 6 -495 13 -512 17 -43 69 -61 167 -59 97 2 134 18 154 65 10 24 13 142 12 521 l0 490 -27 100 c-118 443 -509 733 -962 712 -190 -9 -346 -60 -491 -161 l-64 -45 0 440 c0 485 -1 493 -61 524 -44 23 -195 20 -236 -5z"/>
					<path className="logo-loading o-down" d="M3496 3554 c-437 -94 -746 -477 -746 -926 0 -294 128 -562 355 -743 156 -124 283 -172 553 -209 92 -13 338 42 454 100 163 82 258 163 356 302 114 164 160 304 169 508 6 140 -7 236 -49 359 -104 302 -380 543 -700 609 -97 20 -298 20 -392 0z m424 -373 c173 -77 286 -199 346 -377 29 -87 33 -235 9 -328 -52 -199 -221 -370 -424 -430 -34 -10 -93 -16 -156 -16 -116 0 -177 15 -280 68 -144 74 -273 243 -305 399 -18 87 -8 254 20 327 71 186 224 329 408 382 31 9 90 12 177 11 122 -3 134 -5 205 -36z"/>
					<path className="logo-loading u-down" d="M4720 3542 c-19 -19 -31 -44 -35 -73 -4 -24 -5 -255 -2 -514 4 -519 5 -533 75 -702 157 -377 577 -621 976 -568 138 19 236 48 333 100 243 129 406 332 480 600 l28 100 0 506 c0 544 0 544 -52 568 -39 18 -214 14 -242 -5 -50 -35 -51 -50 -51 -538 0 -294 -4 -474 -11 -508 -15 -73 -77 -202 -127 -265 -52 -65 -151 -136 -245 -176 -64 -27 -86 -31 -192 -35 -110 -4 -127 -2 -199 22 -105 36 -157 67 -242 149 -58 54 -81 86 -118 160 -30 59 -49 115 -56 157 -5 36 -10 266 -10 513 0 488 -1 501 -56 526 -14 6 -70 11 -125 11 -97 0 -102 -1 -129 -28z"/>
					<path className="logo-loading s-down" d="M7035 3556 c-235 -57 -415 -291 -415 -539 0 -94 27 -205 68 -282 40 -74 146 -177 221 -214 119 -60 186 -71 448 -71 195 0 239 -3 266 -16 79 -40 127 -113 127 -194 0 -88 -38 -152 -115 -193 -39 -21 -53 -22 -495 -27 -250 -3 -463 -9 -472 -13 -35 -18 -50 -73 -46 -172 3 -100 16 -127 67 -147 14 -5 223 -9 476 -8 421 0 457 2 525 21 85 23 117 39 188 91 313 231 285 723 -53 927 -113 69 -141 74 -440 80 -264 6 -271 7 -315 31 -50 28 -97 96 -107 153 -13 80 50 192 124 223 26 11 120 14 455 14 233 0 441 5 462 10 22 5 50 20 64 34 22 22 26 36 30 109 5 97 -8 147 -45 177 -25 19 -39 20 -497 19 -333 0 -486 -4 -521 -13z"/>
					<path className="logo-loading e-down" d="M8915 3563 c-116 -26 -193 -52 -267 -90 -244 -125 -410 -335 -484 -609 -15 -56 -19 -103 -18 -244 0 -161 2 -182 27 -263 50 -162 116 -274 234 -398 119 -124 249 -203 406 -244 138 -37 213 -40 679 -35 l428 5 27 28 c26 25 28 35 32 114 4 110 -9 157 -52 180 -29 16 -79 18 -517 23 -478 5 -486 5 -542 28 -245 99 -401 343 -385 602 15 227 134 408 336 508 l94 47 493 5 c480 5 493 6 521 26 43 32 53 61 53 152 0 95 -17 143 -56 161 -18 8 -166 11 -507 10 -265 -1 -491 -3 -502 -6z"/>
					<path className="logo-loading e-down" d="M8955 2791 c-11 -5 -31 -21 -45 -36 -23 -25 -25 -34 -25 -125 0 -84 3 -103 21 -127 40 -54 32 -53 536 -51 l468 3 32 33 c32 31 33 36 37 123 3 101 -7 139 -48 166 -24 17 -62 18 -491 20 -256 1 -474 -2 -485 -6z"/>
				</g>
			</svg>

		</div>
		</Fade>
		</Modal>
		</>
	);
	
}
