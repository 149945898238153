import React, { useState } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown";
import BackToTop from "./BackToTop";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <>
      <nav className="navbar sticky">
        <Link to="/" className="navbar-logo nav-item" onClick={closeMobileMenu}>
          The Neon House 
          <i class="fab fa-neos"></i>
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times close-navbar-icon" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Главная
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to="/market"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Каталог <i className="fas fa-caret-down" />
            </Link>
            {dropdown ? <Dropdown />: null}
          </li>
          <li className="nav-item">
            <Link
              to="/paymentanddelivery"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Оплата и доставка
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/#contacts"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Контакты
            </Link>
          </li>
        </ul>
      </nav>
	  <BackToTop />
    </>
  );
}

export default Navbar;

/* <li>
            <Link
              to="/sign-up"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Sign Up
            </Link>
          </li>*/
