export const MenuItems = [
  {
    title: "Популярное",
    path: "/market?sort=popular",
    cName: "dropdown-link"
  },
  {
    title: "Новинки",
    path: "/market?sort=new",
    cName: "dropdown-link"
  },
];
