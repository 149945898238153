import React, { useEffect, useState } from "react";
import "./BackToTop.scss";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function BackToTop() {
	
	const [isVisible, setVisible] = useState(false);

	useEffect(() => {
		const handleScroll = e => {
			const scrollTop = e.target.documentElement.scrollTop;
			if (scrollTop > 600) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		};
		if (document) {
			document.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (document) {
				document.removeEventListener("scroll", handleScroll);
			}
		};
	}, [setVisible]);
	
	
	return (
		<div className={isVisible?"backtotop":"backtotop hidden"} onClick={()=>{window.scrollTo(0,0);}}><ArrowUpwardIcon className="icon"/></div>
	);
}
