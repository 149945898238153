import React, { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css"; /*
import Test from "../Test/Test";*/

import MarketNavBar from "../Market/NavBar/NavBar";
import useUser from "./useUser";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingScreen from "./LoadingScreen";

const NavBar = React.lazy(() => import("../Working/NavBar/NavBar"));
const Signs = React.lazy(() => import("../Working/Signs/Signs"));
const Orders = React.lazy(() => import("../Working/Orders/Orders"));
const Statistic = React.lazy(() => import("../Working/Statistic/Statistic"));
const Settings = React.lazy(() => import("../Working/Settings/Settings"));

//const MarketNavBar = React.lazy(() => import("../Market/NavBar/NavBar"));
/*const Footer = React.lazy(() => import("../Market/Footer/Footer"));
const Market = React.lazy(() => import("../Market/Shop/Shop"));
const Main = React.lazy(() => import("../Market/Main/Main"));*/

const Footer = React.lazy(() => {
  return Promise.all([
    import("../Market/Footer/Footer"),
    new Promise(resolve => setTimeout(resolve, 400))
  ])
  .then(([moduleExports]) => moduleExports);
});
const Market = React.lazy(() => {
  return Promise.all([
    import("../Market/Shop/Shop"),
    new Promise(resolve => setTimeout(resolve, 400))
  ])
  .then(([moduleExports]) => moduleExports);
});

const Main = React.lazy(() => {
  return Promise.all([
    import("../Market/Main/Main"),
    new Promise(resolve => setTimeout(resolve, 400))
  ])
  .then(([moduleExports]) => moduleExports);
});

const PaymentAndDelivery = React.lazy(() => {
  return Promise.all([
    import("../Market/PaymentAndDelivery/PaymentAndDelivery"),
    new Promise(resolve => setTimeout(resolve, 400))
  ])
  .then(([moduleExports]) => moduleExports);
});


const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

function App(props) {
	var { user, setUser, resetUser } = useUser();
	if (!user) var user = {};
	let path = window.location.pathname;
	
	let working = ["/orders","/signs","/statistic","/settings"];
	
	if(working.includes(path))
	return (
		<Suspense fallback={<CircularProgress />}>
			<Box sx={{ display: "flex" }}>
				<NavBar User={user} setUser={setUser} resetUser={resetUser} />
				<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
					<DrawerHeader />
					<BrowserRouter>
						<Switch>
							<Route path="/orders">
								<Orders User={user} />
							</Route>
							<Route path="/signs">
								<Signs />
							</Route>
							<Route path="/statistic">
								<Statistic User={user} />
							</Route>
							<Route path="/settings">
								<Settings User={user} resetUser={resetUser} />
							</Route>
							<Route >
								<Redirect to="/orders" />
							</Route>
						</Switch>
					</BrowserRouter>
				</Box>
				<ToastContainer
					limit={1}
					position="bottom-left"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Box>
		</Suspense>
	);
	else {
		let params = decodeURI(window.location.search)
			.replace('?', '')
			.split('&')
			.map(param => param.split('='))
			.reduce((values, [ key, value ]) => {
				values[ key ] = value
				return values
			}, {})
		//alert(JSON.stringify(params));
		return (
		<Box> 
			<LoadingScreen/>
			<BrowserRouter>
				<Suspense fallback={<MarketNavBar/>}>
					<MarketNavBar />
					<Box minHeight="91vh" height="100%">
						<Switch>
							<Route path="/market" exact >
								<Market params={params} />
							</Route>
							
							<Route path="/paymentanddelivery" exact >
								<PaymentAndDelivery />
							</Route>
							
							<Route exact component={Main} />
						</Switch>
					</Box>
					<Footer />
				</Suspense>
			</BrowserRouter>
		</Box>
		);
	}
}

export default App;